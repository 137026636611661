<template>
  <v-container>
    <v-row :align="'center'" :justify="'center'">
      <v-stepper v-model="e1" style="max-width: 1000px">
        <v-stepper-header>
          <v-stepper-step style="cursor: pointer" @click="e1 = 1" step="1">
            Общая информация
          </v-stepper-step>
          <v-divider />
          <v-stepper-step style="cursor: pointer" @click="e1 = 2" step="2">
            Контакты
          </v-stepper-step>
          <v-divider />
          <v-stepper-step style="cursor: pointer" @click="e1 = 3" step="3">
            Реквизиты
          </v-stepper-step>
          <v-divider />
          <v-stepper-step style="cursor: pointer" @click="e1 = 4" step="4">
            Условия проживания
          </v-stepper-step>
          <v-divider />
          <v-stepper-step style="cursor: pointer" @click="e1 = 5" step="5">
            Шаблон подтверждения
          </v-stepper-step>
          <v-stepper-step style="cursor: pointer" @click="e1 = 6" step="6">
            SFTP
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row>
              <v-rating
                readonly
                class="pl-2"
                v-model="custom_rating"
                background-color="warning"
                color="warning"
                length="5"
              ></v-rating>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <p>{{ company_status }}</p>
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field
                  v-model="name"
                  dense
                  label="Наименование"
                  disabled
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" md="12">
                <v-select
                  disabled
                  :items="type_service"
                  v-model="type"
                  item-text="name"
                  item-value="value"
                  label="Тип размещения"
                ></v-select>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <p>Описание услуг</p>
                <div ref="editorWrapper">
                  <editor
                    apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
                    v-model="serviceDescription"
                    :init="init"
                  />
                </div>
              </v-col>
              <v-col class="pt-0 pb-0 mt-8" cols="12">
                <p>Порядок проживания</p>
                <div ref="editorWrapperLive">
                  <editor
                    apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
                    v-model="liveDescription"
                    :init="init"
                  />
                </div>
              </v-col>
              <v-col class="pt-0 pb-0 mt-8" cols="12">
                <p>Описание</p>
                <div ref="editorWrapperDescr">
                  <editor
                    apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
                    v-model="description"
                    :init="init"
                  />
                </div>
              </v-col>
              <v-col class="pt-0 pb-0 mt-8" cols="12">
                <v-text-field
                  v-model="telegram_id"
                  dense
                  label="ID телеграм чата"
                />
              </v-col>
              <v-col class="pt-0 pb-0 mt-8" cols="12">
                <v-text-field
                  v-model="talonTelegramId"
                  dense
                  label="ID талон телеграм чата"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="4">
                <v-file-input
                  :disabled="isDisabled()"
                  v-model="singleImage"
                  label="Главное фото"
                  prepend-icon="mdi-camera"
                  show-size
                  chips
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="8">
                <v-row align="center">
                  <v-file-input
                    :disabled="isDisabled()"
                    ref="multiImages"
                    v-model="multiImages"
                    label="Фото"
                    prepend-icon="mdi-camera"
                    multiple
                    counter
                    show-size
                    chips
                  />
                </v-row>
              </v-col>
            </v-row>
            <v-col
              v-for="image in companyImages"
              :key="image.image"
              class="pt-0 pb-0"
              cols="12"
            >
              <v-row class="mb-5" align="center" v-if="image.is_main">
                <img :src="image.image" height="300" />
              </v-row>
              <v-row class="mb-4" align="center" v-if="!image.is_main">
                <img :src="image.image" height="200" />
                <v-btn
                  :disabled="isDisabled()"
                  icon
                  @click="deleteImage(image.id)"
                >
                  <v-icon small color="red darken-2"> mdi-delete </v-icon>
                </v-btn>
              </v-row>
            </v-col>
            <v-btn
              color="primary"
              class="ma-3"
              @click="save"
              :disabled="disabledBtn"
            >
              Сохранить
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-row>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field
                  :disabled="isDisabled()"
                  v-model="phone"
                  dense
                  label="Телефон"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" md="12">
                <v-text-field
                  :disabled="isDisabled()"
                  v-model="email"
                  dense
                  label="E-mail"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" md="12">
                <v-text-field
                  :disabled="isDisabled()"
                  v-model="contactName"
                  dense
                  label="Контактное лицо"
                />
              </v-col>
            </v-row>
            <v-btn color="primary" @click="saveContact" :disabled="disabledBtn">
              Сохранить
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-row>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field
                  :disabled="isDisabled()"
                  v-model="bik"
                  dense
                  label="БИК банка"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field
                  :disabled="isDisabled()"
                  v-model="rnn"
                  dense
                  label="РНН"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field
                  :disabled="isDisabled()"
                  v-model="iban"
                  dense
                  label="ИБАН"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field
                  :disabled="isDisabled()"
                  v-model="bin"
                  dense
                  label="БИН"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field
                  :disabled="isDisabled()"
                  v-model="bankName"
                  dense
                  label="Название банка"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field
                  :disabled="isDisabled()"
                  v-model="knp"
                  dense
                  label="КНП"
                  type="number"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field
                  :disabled="isDisabled()"
                  v-model="kbe"
                  dense
                  label="КБЕ"
                  type="number"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-checkbox v-model="nds" label="Плательщик НДС"></v-checkbox>
              </v-col>
            </v-row>
            <v-btn color="primary" @click="saveBank" :disabled="disabledBtn">
              Сохранить
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-row>
              <v-col class="py-2" cols="12">
                <p>
                  {{
                    booking_time === "full"
                      ? "Фиксированное время заезда"
                      : booking_time === "half"
                      ? "Нефиксированное время заезда"
                      : "Гибрид"
                  }}
                </p>
              </v-col>
              <v-col class="pt-2 pb-0" cols="6" md="6">
                <v-text-field
                  disabled
                  v-model="stdTimeToIn"
                  dense
                  label="Стандартное время заезда"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="6" md="6">
                <v-text-field
                  disabled
                  v-model="stdTimeToOut"
                  dense
                  label="Стандартное время выезда"
                />
              </v-col>

              <v-col class="pt-0 pb-0" cols="6" md="6">
                <v-row class="pr-4 pl-3">
                  <v-checkbox
                    readonly
                    v-model="transfer"
                    dense
                    label="Трансфер"
                  />
                  <v-spacer />
                  <v-checkbox
                    readonly
                    v-model="near_coast"
                    dense
                    label="Рядом с морем"
                  />
                  <v-spacer />
                  <v-checkbox
                    readonly
                    v-model="has_contract"
                    dense
                    label="Договор"
                  />
                </v-row>
              </v-col>
              <v-col v-if="awaitPlace" class="pt-0 pb-0" cols="6" md="6">
                <p>Местность: {{ selectedPlace.place_name }}</p>
                <p>Подместность: {{ selectedPlace.subplace_name }}</p>
              </v-col>
              <v-col class="pt-0 pb-0" cols="3" md="3">
                <v-text-field
                  disabled
                  dense
                  label="Долгота"
                  type="number"
                  v-model="latitude"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="3" md="3">
                <v-text-field
                  disabled
                  dense
                  label="Широта"
                  type="number"
                  v-model="longitude"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="6" md="6">
                <v-select
                  v-model="fields"
                  multiple
                  :items="fieldItems"
                  label="Обязательные поля для заполнения"
                ></v-select>
              </v-col>
              <v-col class="pt-0 pb-0" cols="6" md="6">
                <v-btn color="warning" @click="saveFields">Сохранить</v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="5">
            <v-row>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <p>Заголовок</p>
                <div ref="editorWrapperHeader">
                  <editor
                    apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
                    v-model="email_header"
                    :init="init"
                  />
                </div>
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <p>Нижний колонтитул</p>
                <div ref="editorWrapperFooter">
                  <editor
                    apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
                    v-model="email_footer"
                    :init="init"
                  />
                </div>
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-file-input
                  prepend-icon="mdi-camera"
                  label="Логотип"
                  v-model="logoImage"
                  @change="addLogo($event)"
                ></v-file-input>
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <img id="imagePreview" :src="logoSource" />
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              @click="saveTemplate"
              :loading="btnLoading"
              :disabled="btnLoading"
            >
              Сохранить
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="6">
            <v-row>
              <v-col class="pt-2 pb-0" cols="6" md="6">
                <v-text-field
                  v-model="sftp_hostname"
                  dense
                  label="SFTP название хоста"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="6" md="6">
                <v-text-field
                  v-model="sftp_username"
                  dense
                  label="Имя пользователя"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="6" md="6">
                <v-text-field
                  v-model="sftp_password"
                  dense
                  label="Пароль пользователя"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="6" md="6">
                <v-text-field
                  v-model="sftp_remote_path"
                  dense
                  label="Путь к отчету"
                />
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              @click="saveSftp"
              :loading="btnLoading"
              :disabled="btnLoading"
            >
              Сохранить
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-row>
  </v-container>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { CompaniesApiUrls } from "@/services/accommodationRequests/companies.api.js";
import user from "../../../store/auth";
import toast from "../../../plugins/toast";

export default {
  name: "ObjectInformation",
  components: {
    editor: Editor
  },
  data() {
    return {
      init: {
        height: 300,
        menubar: false,
        menu: {
          edit: { title: "Edit", items: "undo redo | selectall" }
        },
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount"
        ],
        toolbar:
          "undo redo | fontselect | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help"
      },
      fields: [],
      fieldItems: [
        {
          text: "Имя",
          value: "first_name"
        },
        {
          text: "Фамилия",
          value: "last_name"
        },
        {
          text: "Отчество",
          value: "middle_name"
        },
        {
          text: "Номер телефона",
          value: "phone_number"
        },
        {
          text: "Почта",
          value: "email"
        },
        {
          text: "Гендер",
          value: "gender"
        },
        {
          text: "Комментарий",
          value: "comment"
        },
        {
          text: "Паспорт",
          value: "document_number"
        }
      ],
      disabledBtn: false,
      logoSource: "",
      logoImage: null,
      btnLoading: false,
      companyImages: [],
      type_service: [
        { name: "Зона отдыха", value: "rest_zone" },
        { name: "Гостиница", value: "hotel" },
        { name: "Гостевой дом", value: "guest_house" },
        { name: "Санаторий", value: "sanatorium" },
        { name: "Активный отдых", value: "leisure" }
      ],
      serviceDescription: "",
      liveDescription: "",
      description: "",
      awaitPlace: false,
      e1: 1,
      type: null,
      phone: null,
      email: null,
      contactName: null,
      stdTimeToIn: null,
      stdTimeToOut: null,
      cancelPerm: null,
      minDays: null,
      pay: null,
      transfer: null,
      near_coast: null,
      has_contract: null,
      name: null,
      telegram_id: null,
      talonTelegramId: null,
      bik: null,
      rnn: null,
      iban: null,
      bin: null,
      bankName: null,
      description: "",
      multiImages: [],
      singleImage: null,
      custom_rating: null,
      company_status: null,
      latitude: null,
      longitude: null,
      selectedPlace: null,
      email_header: "",
      email_footer: "",
      knp: null,
      kbe: null,
      nds: false,
      booking_time: "",
      sftp_password: null,
      sftp_username: null,
      sftp_hostname: null,
      sftp_remote_path: null
    };
  },
  async created() {
    this.loadInfo();
  },
  watch: {
    logoImage() {
      if (this.logoImage == undefined) {
        const preview = document.querySelector("#imagePreview");
        preview.src = "";
      }
    }
  },
  methods: {
    async saveFields() {
      let required_fields = [];
      this.fields.forEach(e => {
        required_fields.push({
          name: e
        });
      });
      this.disabledBtn = true;
      await CompaniesApiUrls.setInfo({
        required_fields: required_fields
      });
      this.disabledBtn = false;
      toast.success("Данные успешно поменялись");
    },
    async loadInfo() {
      let response = await CompaniesApiUrls.getInfo();
      this.companyImages = [];
      if (response.main_image) {
        this.companyImages.push({
          image: response.main_image,
          is_main: true
        });
      }
      for (const [key, value] of Object.entries(response.required_fields)) {
        this.fields.push(value.name);
      }
      this.companyImages = [...this.companyImages, ...response.image_list];
      this.logoSource = response.logo;
      this.email_header = response.email_header;
      this.email_footer = response.email_footer;
      this.name = response.name;
      this.telegram_id = response.telegram_id;
      this.talonTelegramId = response.talon_telegram_id;
      this.contactName = response.contact_person;
      this.phone = response.phone_number;
      this.email = response.email;
      this.bik = response._bik;
      this.iban = response._iban;
      this.bin = response._bin;
      this.bankName = response._bank;
      this.stdTimeToIn = response.check_in_time;
      this.stdTimeToOut = response.check_out_time;
      this.pay = response.payment_period;
      this.transfer = response.has_transfer;
      this.near_coast = response.near_coast;
      this.has_contract = response.has_contract;
      this.custom_rating = response.custom_rating;
      this.company_status = response.company_statuses;
      this.latitude = response.latitude;
      this.longitude = response.longitude;
      this.knp = response._knp;
      this.kbe = response._kbe;
      this.nds = response.nds;
      this.booking_time = response.booking_time;
      this.sftp_hostname = response.sftp_hostname;
      this.sftp_remote_path = response.sftp_remote_path;
      this.selectedPlace = {
        place_name: response.city_name,
        subplace_name: response.place_name
      };
      if (response.city_name != null) {
        this.awaitPlace = true;
      } else {
        this.awaitPlace = false;
      }
      this.description = response.description;
      if (response.description_services === null) {
        this.serviceDescription = "";
      } else {
        this.serviceDescription = response.description_services;
      }
      if (response.description_accommodation === null) {
        this.liveDescription = "";
      } else {
        this.liveDescription = response.description_accommodation;
      }
      this.rnn = response._trn;
      this.type = response.accommodation_type;
    },
    addLogo() {
      const preview = document.querySelector("#imagePreview");
      const file = this.logoImage;
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function() {
          preview.src = reader.result;
        },
        false
      );
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    async saveSftp() {
      try {
        const body = {
          sftp_hostname: this.sftp_hostname,
          sftp_remote_path: this.sftp_remote_path
        };
        if (this.sftp_username) body.sftp_username = this.sftp_username;
        if (this.sftp_password) body.sftp_password = this.sftp_password;

        this.btnLoading = true;
        await CompaniesApiUrls.setInfoSftp(body);
        toast.success("SFTP данные успешно поменялись!");
      } catch (e) {
        console.log(e);
      } finally {
        this.btnLoading = false;
      }
    },
    async saveTemplate() {
      this.btnLoading = true;
      var formdata = new FormData();
      formdata.append("email_header", this.email_header);
      formdata.append("email_footer", this.email_footer);
      formdata.append("logo", this.logoImage);
      await CompaniesApiUrls.setInfoTemplate(formdata);
      this.disabledBtn = false;
      toast.success("Данные заголовков успешно поменялись");
      this.btnLoading = false;
      this.loadInfo();
    },
    async saveContact() {
      this.disabledBtn = true;
      await CompaniesApiUrls.setInfo({
        phone_number: this.phone,
        email: this.email,
        contact_person: this.contactName
      });
      this.disabledBtn = false;
      toast.success("Контактные данные успешно поменялись");
    },
    async saveBank() {
      this.disabledBtn = true;
      await CompaniesApiUrls.setInfo({
        _bin: this.bin,
        _trn: this.rnn,
        _iban: this.iban,
        _bik: this.bik,
        _bank: this.bankName,
        _knp: this.knp,
        _kbe: this.kbe,
        nds: this.nds
      });
      this.disabledBtn = false;
      toast.success("Данные банка успешно поменялись");
    },
    isDisabled() {
      return user.getters.getIsAdmin !== "true";
    },
    async deleteImage(id) {
      await CompaniesApiUrls.deleteInfoImage(id);
      this.loadInfo();
    },
    async save() {
      this.disabledBtn = true;
      await CompaniesApiUrls.setInfo({
        description: this.description,
        description_services: this.serviceDescription,
        description_accommodation: this.liveDescription,
        telegram_id: this.telegram_id,
        talon_telegram_id: this.talonTelegramId
      });
      this.disabledBtn = false;
      if (!this.singleImage && !this.multiImages.length) {
        toast.success("Данные компании успешно поменялись");
      }
      if (this.singleImage) {
        var formdata = new FormData();
        formdata.append(
          "main_image",
          this.singleImage,
          this.singleImage.fileName
        );
        await CompaniesApiUrls.setInfoImage(formdata);
        toast.success("Данные компании успешно поменялись");
        this.singleImage = null;
      }
      if (this.multiImages.length) {
        for (let i = 0; i < this.multiImages.length; i++) {
          let formdata = new FormData();
          formdata.append(
            "image",
            this.multiImages[i],
            this.multiImages[i].fileName
          );
          await CompaniesApiUrls.createInfoImage(formdata);
        }
        this.multiImages = [];
      }
      this.loadInfo();
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
#imagePreview {
  width: 300px;
  height: auto;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  border: 1px solid black;
}
</style>
